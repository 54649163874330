@import '../../variables.module';

.ripples {
  box-sizing: border-box;
  position: relative;

  &.rounded {
    border-radius: 50%;
  }
}

.button {
  align-items: center;
  background-color: transparent;
  border-style: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  padding: 0;
  width: 100%;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
}
