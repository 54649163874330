@import '../../variables.module';

.link {
  align-items: center;
  background-color: transparent;
  border-width: 0;
  color: $secondary;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  outline: none;
  padding: 0;
  text-decoration: none;

  &:hover {
    color: darken($secondary, 10%);
  }

  &:hover svg {
    fill: darken($secondary, 10%);
  }

  &.m {
    font-size: 16px;
  }

  &.s {
    font-size: 13px;
  }

  &.xs {
    font-size: 10px;
  }

  &.disabled {
    color: $muted;
    cursor: not-allowed;
  }

  &.loading {
    color: $muted;
    cursor: wait;
  }
}
