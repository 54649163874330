@import '../../variables.module';

.wrapper {
  background-color: $bg-secondary;
  border: 1px solid $border-primary;
  box-sizing: border-box;
  color: $text-primary;
  @include m {
    border-radius: 10px;
    margin: 10px;
  }
}

.wrapper-checked {
  background-color: $primary;
  border: 1px solid $border-primary;
  box-sizing: border-box;
  color: #fff;
  @include m {
    border-radius: 10px;
    margin: 10px;
  }
}

.wrapper:hover {
  background-color: rgba(166, 206, 57, 0.14);
  border: 1px solid #e1eac4;
  transition: border 0.2s ease-in-out;
}

.container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-width: 300px;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.input {
  appearance: none;
  display: none;

  + span {
    display: inline-block;
    font-size: 15px;
    padding: 9px;
    padding-left: 42px;
    position: relative;
    @include m {
      padding: 32px;
      padding-left: 60px;
    }
  }

  + span::before {
    background: #fff;
    border: 1px solid $border-secondary;
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    left: 16px;
    margin-right: 5px;
    position: absolute;
    top: 9px;
    width: 16px;
    @include m {
      left: 32px;
      top: 33px;
    }
  }

  + span::after {
    background: $secondary;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 20px;
    opacity: 0;
    position: absolute;
    top: 13px;
    transform: scale(0, 0);
    transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    width: 10px;
    @include m {
      left: 36px;
      top: 37px;
    }
  }

  &:checked + span::after {
    opacity: 1;
    transform: scale(1, 1);
  }

  &:hover + span::before {
    border: 1px solid $secondary;
    transition: border 0.2s ease-in-out;
  }

  &:checked + span::before {
    border: 1px solid $primary;
  }
}
