@import '../../../variables.module';

.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.loader {
  display: inline-block;
  height: 32px;
  left: 2px;
  position: relative;
  top: -1px;
  width: 32px;
}

.circle {
  animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
}

.circle:nth-child(2) {
  animation-delay: -0.5s;
}

.circle-gray {
  animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  border: 2px solid $muted;
  border-radius: 50%;
  position: absolute;
}

.circle-gray:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes loader {
  0% {
    height: 0;
    left: 16px;
    opacity: 1;
    top: 16px;
    width: 0;
  }

  95% {
    height: 32px;
    left: 0;
    opacity: 0;
    top: 0;
    width: 32px;
  }

  100% {
    height: 0;
    left: 16px;
    opacity: 0;
    top: 16px;
    width: 0;
  }
}
