@import '../../variables.module';

.container {
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 15px 40px 15px 15px;
  position: relative;
  width: 100%;

  @include m {
    width: 90%;
  }
}

.error {
  background-color: lighten($error, 5%);
  border: $error 1px solid;
  color: $bg-secondary;
}

.success {
  background-color: $primary;
  border: darken($primary, 5%) 1px solid;
  color: $bg-secondary;
}

.warning {
  background-color: $warning;
  border: darken($warning, 5%) 1px solid;
  color: $bg-secondary;
}

.content {
  align-items: center;
  display: flex;
  justify-content: space-between;

  a {
    color: inherit;
  }
}

.close {
  background-color: transparent;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 10px;
  position: absolute;
  right: 4px;
  top: 3px;
}

.type-icon {
  height: 26px;
  padding-right: 15px;
  width: 26px;
}

.icon {
  fill: $bg-secondary;
  height: 12px;
  width: 12px;
}
