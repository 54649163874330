@import '../../variables.module';

@keyframes errorSlide {
  from {
    height: 0;
    opacity: 0;
    transform: translate3d(0, -15%, 0);
  }

  to {
    height: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}

.field {
  background-color: #fff;
  border: 1px solid $border-primary;
  border-bottom-width: 2px;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 50px;
  position: relative;
  width: 100%;

  &:focus-within {
    border-color: $secondary;
  }
}

.select {
  appearance: none;
  background: url('../../assets/icons/chevron-sharp.svg') no-repeat right;
  border: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  margin-right: 15px;
  outline: none;
  padding: 20px 15px 5px;
  user-select: none;
  width: 100%;
}

.select:focus + label {
  transform: scale(0.7) translateY(-40%);
}

.badges {
  align-items: center;
  display: flex;
  justify-content: center;

  .badge {
    display: block;
    padding: 0 10px;
  }
}

.divider {
  background-color: $border-primary;
  height: 30px;
  width: 1px;
}

.error-icon {
  height: 100%;
}

.label {
  align-items: center;
  bottom: 0;
  color: $text-muted;
  display: flex;
  left: 15px;
  margin: auto;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform-origin: left;
  transition: transform 100ms ease-in-out;

  &.float {
    transform: scale(0.7) translate3d(0, -40%, 0);
  }
}

.error-message {
  align-items: center;
  animation: errorSlide 200ms ease-in-out;
  color: $error;
  display: flex;
  font-size: 14px;
  width: 180px;
}
