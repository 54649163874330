@import '../../variables.module';

.card {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding: 70px 100px;

  @include m {
    padding: 25px 50px;
  }
}

.card-success {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding: 55px;

  @include m {
    padding: 25px 20px;
  }
}

.content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.heading {
  margin-bottom: 30px;
  text-align: center;

  @include m {
    margin-bottom: 25px;
  }
}

.lottie-wrapper {
  max-width: 100px;

  @include m {
    margin-bottom: 8px;
  }
}

@media only percy {
  .lottie-wrapper {
    visibility: hidden;
  }
}

.title-description {
  font-weight: 500;
  text-align: center;
}

.description {
  margin-bottom: 60px;
  text-align: center;
}

.button {
  margin-bottom: 15px;

  @include m {
    margin-bottom: 0;
  }
}
