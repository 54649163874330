@import '../../variables.module';

.wrapper {
  border-bottom: 4px solid rgb(108, 155, 65);
  position: relative;
  user-select: none;
  width: 100%;

  &::before,
  &::after {
    bottom: 0;
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 48px;
    z-index: 1;
  }

  // prettier-ignore
  &::before {
    background: linear-gradient(270deg, rgba(166, 204, 51, 0), $primary 100%);
    left: 0;
  }

  // prettier-ignore
  &::after {
    background: linear-gradient(-270deg, rgba(166, 204, 51, 0) 0%, $primary 100%);
    right: 0;
  }
}

.heading {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  padding: 0 24px;
  text-align: center;

  &.disabled {
    color: rgba(255, 255, 255, 0.8);
  }
}

.container {
  cursor: move;
  cursor: grab;
  height: 65px;
  overflow: hidden;
  position: relative;
  touch-action: pan-x; // disable vertical scroll for Android
  width: 100%;

  &:active {
    cursor: move;
    cursor: grabbing;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.base {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate3d(-50%, 0, 0);
}

.view {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  font-size: 28px;
  line-height: 1;
  margin-bottom: 5px;
  padding-top: 13px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.item {
  color: #fff;
  display: inline-block;
  text-align: center;
  transform-origin: bottom;
  transition: transform 0.3s;

  &.disabled {
    color: rgba(255, 255, 255, 0.8);
  }

  &.active {
    font-weight: 500;
    transform: scale(1.6);
  }
}

.measurements {
  background: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 17' fill='rgb(108, 155, 65)'><path d='M29 0h1v17h-1zM14 12h1v5h-1zm30 0h1v5h-1zm15-4h1v9h-1z'/></svg>")
    center;
  bottom: 0;
  height: 17px;
  right: 0;
  width: 100%;
}

.limit-measurements {
  @extend .measurements;
  background: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 17' fill='rgb(255, 0, 0)'><path d='M0,0 L1,0 L1,17 L0,17 L0,0 Z M45,12 L46,12 L46,17 L45,17 L45,12 Z M15,12 L16,12 L16,17 L15,17 L15,12 Z M30,8 L31,8 L31,17 L30,17 L30,8 Z'/></svg>")
    left;
  right: 1px;
  width: 50%;

  &::after {
    background-color: $error;
    bottom: -4px;
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    width: 100%;
  }
}

.label {
  align-items: baseline;
  display: flex;
  justify-content: center;

  &.bottom {
    align-items: center;
    flex-direction: column;
  }

  &.top {
    align-items: center;
    flex-direction: column-reverse;
  }

  &.left {
    flex-direction: row-reverse;
  }
}

.unit {
  font-size: 10px;
  font-weight: 400;
}
