@import '../../variables.module';

.calculator-wrapper {
  overflow: visible;
  padding: 0;

  @include m {
    border-width: 0;
    height: auto;
  }
}

.slider {
  @include m {
    display: none;
  }

  &:first-child {
    margin-bottom: 24px;
  }
}

.swiper {
  display: none;
  margin-bottom: 30px;

  @include m {
    display: block;
  }
}

.toggle {
  background-color: #fff;
  border-radius: 12px;
  border-style: none;
  cursor: pointer;
  display: flex;
  margin: 15px 0;
  padding: 0;
  position: relative;

  @include m {
    margin: 0 auto 20px;
  }
}

.new-label {
  pointer-events: none;
  position: absolute;
  right: -25px;
  top: -15px;
}

.toggle-option {
  border-radius: 12px;
  color: $secondary;
  font-size: 14px;
  max-width: 70px;
  padding: 5px 35px;
  white-space: nowrap;
  width: 100%;

  &.active {
    background-color: $secondary;
    color: #fff;
  }
}

.instalment-heading {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 500;

  @include m {
    margin-bottom: 15px;
    padding: 0 20px;
    text-align: center;
  }
}

.instalment-cards {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  margin-top: 20px;
  width: 100%;

  @include m {
    flex-shrink: 1;
    flex-wrap: nowrap;
    margin: 0 auto;
    overflow-x: auto;
    padding: 5px 0;
  }
}

.instalment-card-container {
  display: flex;
  flex-basis: 30%;
  overflow: visible;
  padding: 5px;

  @include m {
    flex: 1;
    flex-basis: 26%;

    &:last-child {
      padding-right: 20px;
    }

    &:first-child {
      padding-left: 20px;
    }
  }
}

.instalment-card {
  flex: 1;
  margin-top: 5px;
}

.new-icon {
  position: absolute;
  right: -20px;
  top: -12px;
}
