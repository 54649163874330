@import '../../variables.module';

.container {
  margin: 68px 0 50px;
}

.item {
  align-items: center;
  border: 1px solid transparent;
  color: $text-primary;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  margin-bottom: 7px;
  padding: 10px 18px 10px 12px;
  text-decoration: none;
  width: fit-content;

  path {
    fill: $text-primary;
  }

  &:hover {
    color: $primary;

    path {
      fill: $primary;
    }
  }

  &.selected {
    background: $bg-secondary;
    border: 1px solid $border-primary;
    border-radius: 22px;
    color: $primary;
  }

  &.disabled {
    color: $text-muted;
    pointer-events: none;

    path {
      fill: $text-muted;
    }
  }
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  width: 20px;
}

.selected-icon {
  path {
    fill: $primary;
  }
}

.logout {
  color: $text-primary;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  width: fit-content;

  &:hover {
    color: $primary;

    path {
      fill: $primary;
    }
  }

  &.disabled {
    color: $text-muted;
    pointer-events: none;
  }

  p {
    margin: 0;
    margin-right: 8px;
  }
}

.title {
  font-weight: 500;
  margin: 0;
}
