@import '../../variables.module';

@keyframes grow {
  from {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    transform: scale(1);
  }

  to {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
  }
}

.labels {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.heading {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 500;

  @include m {
    text-align: center;
  }
}

.amount-label {
  color: #fff;
  font-size: 32px;
  font-weight: 500;

  .units {
    font-size: 12px;
  }
}

.slider {
  height: 16px;
}

.thumb {
  bottom: -9px;

  &:focus {
    outline: none;
  }
}

.handle {
  align-items: center;
  background-color: $secondary;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  cursor: grab;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 30px;

  &:active {
    animation: grow 50ms ease-out;
    animation-fill-mode: forwards;
    cursor: grabbing;
  }
}

.handle-icon {
  fill: #fff;
  height: 12px;
  width: 12px;
}

.tooltip {
  background-color: #fff;
  border-radius: 5px;
  bottom: 42px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  min-width: 55px;
  position: absolute;
  transform: translate3d(0, 0, 0);

  &::after {
    background: #fff;
    bottom: -5px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    content: '';
    height: 10px;
    left: calc(50% - 5px);
    position: absolute;
    transform: rotate(-45deg);
    width: 10px;
    z-index: -1;
  }
}

.amount {
  align-items: baseline;
  background: #fff;
  border-radius: 5px;
  display: flex;
  height: auto;
  justify-content: center;
  min-width: 50px;
  padding: 6px 7px;
  white-space: nowrap;
  width: auto;

  .value {
    font-size: 16px;
    font-weight: 500;
  }

  .units {
    color: $text-muted;
    font-size: 12px;
    font-weight: 500;
    margin-left: 2px;
  }
}

.track {
  border-bottom: 4px solid $secondary;
  height: 9px;

  &:nth-of-type(1) {
    background-image: url('../../assets/icons/ruler.svg');
    background-position: -20px 0;
  }

  &:nth-of-type(2) {
    background-image: url('../../assets/icons/ruler.svg');
    background-position: right;
    border-bottom: 4px solid #89ab2b;
  }
}

.limits {
  color: #fff;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-top: 15px;
}
