@import '../../variables.module';

.wrapper {
  align-items: center;
  background-color: $bg-secondary;
  display: flex;
  height: 40px;
  padding: 5px 0;

  @include xs {
    height: auto;
  }

  + .wrapper {
    border-top: 1px solid #e0e3f1;
  }
}

.info {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;

  @include xs {
    display: block;
  }
}

.label {
  color: $text-secondary;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
  padding-right: 10px;
  vertical-align: middle;
  width: 210px;
}

.label-with-msg {
  line-height: 15px;
}

.msg {
  color: $error;
  font-size: 11px;
}

.value {
  color: $text-primary;
}

.button {
  font-size: 8px;
  margin-left: auto;
  width: 60px;
}

.icon {
  animation: pulse 0.5s;

  path {
    fill: $secondary;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.25);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
