@import '../../variables.module';

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .item {
    align-items: center;
    display: flex;
    flex-grow: 1;

    &:first-child {
      flex-basis: 0;
    }

    &:last-child {
      flex-basis: 0;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }

    &.static-button {
      margin-right: 20px;
    }
  }
}

.chevron {
  flex: 1;
  margin-right: 9px;
  transform: rotateZ(90deg);
}

.white-chevron {
  fill: $bg-secondary;
  flex: 1;
  margin-right: 9px;
  transform: rotateZ(90deg);
}

.mobile-heading {
  color: $text-black-100;
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin: 0 20px;
  text-align: center;
  word-wrap: no-wrap;
}

.logo-small {
  display: block;
}

.menu-button {
  display: flex;
  height: 30px;
  width: 30px;
}

.menu {
  background-color: $bg-secondary;
  box-sizing: border-box;
  display: flex;
  display: none;
  flex: 1;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.white-color {
  color: $bg-secondary;
}
