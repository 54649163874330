@import '../variables.module';

.wrapper-guardRoute {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.banner {
  padding: 15px;
  background-color: $error;
  border-style: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-family: 'Core Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
  transition: background-color 200ms, color 200ms, border-color 200ms;
  max-width: 100%;
  width: 100%;
}
