@import '../../../variables.module';

@keyframes bottomFadeIn {
  from {
    opacity: 0;
    transform: translateY(30%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.notification {
  align-self: flex-end;
  animation: bottomFadeIn 500ms ease-in-out;
  margin-bottom: 20px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 400px;
  z-index: 11;

  @include m {
    position: sticky;
    width: 100%;

    span {
      display: flex;
      flex-direction: row;
    }
  }
}

.notification-lower-position {
  top: 100px;
}
