@import '../../variables.module';

.container {
  display: none;
  height: 100%;
  position: fixed;
  z-index: 10;

  @include m {
    display: flex;
  }
}

.scroll-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 30px;
}

.heading {
  font-weight: 500;
  margin-top: 30px;
}

.menu {
  flex: 1;
  margin: 28px 0 18px;
}

.item {
  color: $text-primary;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  margin-bottom: 12px;
  padding: 8px 8px 8px 0;
  text-decoration: none;

  &.selected {
    background: $bg-secondary;
    border: 0;
    border-radius: 0;
    color: $primary;
    text-align: center;
    width: fit-content;
  }

  &.disabled {
    color: $text-muted;
    pointer-events: none;

    path {
      fill: $text-muted;
    }
  }
}

.icon {
  align-items: center;
  display: flex;
  margin-right: 20px;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.close-icon {
  box-sizing: border-box;
  height: 30px;
  padding: 5px;
  width: 30px;
}

.selected-icon {
  align-items: center;
  display: flex;

  path {
    fill: $primary;
  }
}

.logout {
  color: $text-primary;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  text-decoration: none;
  width: fit-content;

  p {
    margin: 0;
    margin-right: 8px;
  }

  svg {
    margin-top: 3px;
  }
}

.title {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
}

.header {
  background: $bg-secondary;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 10px;
  position: absolute;
  top: 0;
  width: 100%;
}

.logo {
  margin-left: 30px;
}
