@import '../../../variables.module';

.footer {
  align-items: flex-start;
  border-top: 1px solid $border-primary;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
  padding: 20px 0 10px;
  width: 100%;

  @include m {
    padding: 30px 20px;
  }
}

.first-container {
  flex-basis: 322px;
}

.contacts {
  display: flex;
  gap: 30px;
  margin-bottom: 15px;
}

.contact {
  align-items: center;
  display: flex;
  gap: 13px;
}

.envelope,
.phone {
  fill: $grey-2;
}

.envelope {
  flex-shrink: 0;
  width: 20px;
}

.second-container {
  align-items: center;
  display: flex;
}

.helper-text {
  margin: -2px 0 0;

  a,
  span {
    color: $grey-2;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
  }
}

.helper-separator {
  margin: 0 10px;
}

.helper-text-link {
  a,
  span {
    &:hover {
      color: $grey-2;
      text-decoration: underline;
    }
  }
}

.phone {
  flex-shrink: 0;
  width: 16px;
}

.link {
  color: $grey-2;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.work-hours {
  // Specificity
  /* stylelint-disable-next-line declaration-no-important */
  color: $grey-2 !important;
  // Specificity
  /* stylelint-disable-next-line declaration-no-important */
  font-size: 11px !important;
  letter-spacing: -0.2px;
  line-height: 13px;
  opacity: 0.83;
}
