@import '../../variables.module';

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .item {
    align-items: center;
    display: flex;
    flex-grow: 1;

    &:first-child {
      flex-basis: 0;
    }

    &:last-child {
      flex-basis: 0;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }
}

.logout {
  fill: $secondary;
  margin-left: 9px;
}

.chevron {
  flex: 1;
  margin-right: 9px;
  transform: rotateZ(90deg);
}
