@import '../../variables.module';

.wrapper {
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  filter: blur(3px);
  text-shadow: 0 0 12px $text-primary;
  user-select: none;
}

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}
