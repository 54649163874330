@import '../../variables.module';

.discount-banner {
  background-color: $bg-secondary;
  display: flex;
  justify-content: center;
  position: relative;
  width: inherit;
}

.icon-wrapper {
  align-items: center;
  background-color: #ff6402;
  border-radius: 3.6px;
  color: $bg-secondary;
  display: flex;
  flex-direction: row;
  margin: 20px;
  padding: 12px;
  text-align: center;

  @include s {
    flex-direction: column;
  }
}

.icon {
  margin-right: 8px;

  @include s {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.text-wrapper {
  flex-direction: column;
  font-size: 12px;
  margin: auto 0;
  margin-right: 10px;
}

.heading {
  font-size: 14px;
}

.close {
  background-color: transparent;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  height: 24px;
  position: absolute;
  right: 14px;
  top: 14px;

  @include xs {
    position: relative;
  }
}
