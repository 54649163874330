@import '../../variables.module';

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding-bottom: 12px;

  &.border {
    border-bottom: 1px solid $border-primary;
  }
}

.none {
  display: none;
}

.container {
  align-items: center;
  color: $text-primary;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  min-height: 25px;
  overflow: hidden;
  padding-left: 35px;
  position: relative;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
  }

  &.pending {
    cursor: wait;
  }
}

.container input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkmark {
  background-color: #fff;
  border: 1px solid #dcdfe2;
  border-radius: 3px;
  box-sizing: border-box;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  transition: border 0.2s ease-in-out;
  width: 25px;
}

.checkmark:hover {
  border: 1px solid $secondary;
  transition: border 0.2s ease-in-out;
}

.invisible {
  display: none;
}

.invisible:checked ~ .checkmark {
  background-color: $secondary;
  border-color: $secondary;
}

.invisible:checked ~ .error {
  background-color: $secondary;
  border: 0;
}

.invisible:disabled ~ .checkmark {
  background-color: #eee;
  border-color: #eee;
}

.error {
  background: transparentize($error, 0.7);
  border: 1px solid $error;
}

.checkmark::after {
  content: '';
  display: none;
  position: absolute;
}

.invisible:checked ~ .checkmark::after {
  display: block;
}

.container .checkmark::after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  height: 10px;
  left: 8px;
  top: 3px;
  transform: rotate(45deg);
  width: 5px;
}

.error-message {
  align-items: center;
  animation: errorSlide 200ms ease-in-out;
  color: $error;
  display: flex;
  font-size: 14px;
  margin: 10px 0;
  padding-left: 35px;
  width: 100%;
}

.content a {
  color: $secondary;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: darken($secondary, 10%);
  }
}
