@import '../../variables.module';

.container {
  @include m {
    display: flex;
    flex: 1;
    max-height: 520px;
  }
}

.card {
  border: unset;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;

  @include m {
    flex: 1;
    padding-top: 50px;
  }

  ul {
    padding: 8px 0 0 16px;
    text-align: left;
  }

  li {
    margin-bottom: 1rem;
  }
}

.description {
  margin-top: 15px;
  max-width: 370px;
}

.icon {
  margin-bottom: 15px;
}
