@import '../../../variables.module';

.title {
  color: $grey-3;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.email-phone {
  color: $grey-3;
  font-size: 17px;
  font-weight: 600;
  line-height: 37px;
}

.work-hours {
  color: $grey-3;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.pay-link {
  color: $text-black-100;
  font-size: 13px;
  font-weight: 400;
  margin-top: 13px;

  svg {
    margin-right: 5px;
  }
}
