@import '../../variables.module';

.term-card {
  border-radius: 10px;
  box-shadow: 0 0 0 1px transparentize($secondary, 0.4);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  position: relative;

  &.active {
    box-shadow: 0 0 0 3px $secondary;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.check {
  left: -11px;
  position: absolute;
  top: -11px;
  width: 26px;
  z-index: 5;

  @include s {
    left: 0;
    margin: 0 auto;
    right: 0;
    width: 26px;
  }

  circle {
    fill: $secondary;
  }
}

@media only percy {
  .check {
    display: none;
  }
}

.touchable-container {
  background-color: $bg-secondary;
  border-radius: 10px;
  flex: 1;

  &.active {
    &:focus-within {
      box-shadow: none;
    }
  }

  &:hover {
    background-color: darken($bg-secondary, 3%);
  }

  .touchable {
    flex-direction: row;
    padding: 13px 0;

    @include s {
      flex-direction: column;
    }
  }
}

.term-card-duration {
  align-items: center;
  color: $text-primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

  @include s {
    padding: 0 20px 5px;
  }

  .amount {
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
  }

  .label {
    color: $text-muted;
    font-size: 11px;
  }
}

.amounts {
  display: flex;
}

.term-card-payment {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 0 10px 0 0;

  @include s {
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 5px 10px 0;
    width: 100%;
  }

  .amount {
    font-size: 10px;
    font-weight: 500;

    @include s {
      font-size: 12px;
    }

    &.large {
      font-size: 11px;
    }
  }

  .label {
    color: $text-muted;
    font-size: 10px;
    max-width: 90px;

    &.large {
      color: $text-primary;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include s {
    align-items: center;
    flex-direction: column;
  }

  &.badge {
    background-color: $border-accent;
    border: 1px solid darken($border-accent, 5%);
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 5px;
    padding: 3px 5px;
  }
}
