@import '../../variables.module';

.paragraph {
  color: $text-primary;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  width: 100%;

  &.medium {
    font-weight: 500;
  }

  &.secondary {
    color: $text-secondary;
  }

  &.small {
    color: $text-muted;
    font-size: 12px;
  }
}
