@import '../../variables.module';

.heading {
  color: $text-primary;
  font-weight: 500;
  margin: 0;
  padding: 0;
  width: 100%;

  &.s {
    font-size: 14px;
  }

  &.m {
    font-size: 20px;

    @include xs {
      font-size: 14px;
    }
  }

  &.l {
    font-size: 32px;
    line-height: 40px;

    @include s {
      font-size: 22px;
      line-height: 32px;
    }
  }
}
