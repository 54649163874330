@import '../../variables.module';

@keyframes bottomFadeIn {
  from {
    opacity: 0;
    transform: translateY(30%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.overlay {
  align-items: center;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  @include s {
    justify-content: flex-end;
  }
}

.container {
  max-width: 600px;

  @include s {
    width: 100%;
  }
}

.fade {
  animation: bottomFadeIn 500ms ease-in-out;
}

.card {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  max-height: 100vh;
  max-height: 80vh;
  padding: 0;
  z-index: 1;

  @include s {
    border-radius: 30px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 90vh;
  }
}

.heading {
  box-sizing: border-box;
  color: $text-muted;
  padding: 15px;
  text-align: center;
}

.content {
  box-sizing: border-box;
  overflow-y: auto;
  padding: 20px 50px 50px;

  @include s {
    padding: 0 25px 25px;
  }
}

.close {
  align-items: center;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 5px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
}
