// Colors
$primary: #a6cc33;
$primary-banner: #a8cc3c;
$secondary: #107038;
$muted: #b5b5bf;
$error: #f04942;
$error-background-100: #ef554f;
$error-background-50: #fce2e1;
$error-border-100: #ff2d2d;
$error-font-100: #e95353;
$error-5: #ef554f;
$error-10: #fce2e1;
$error-15: #e95353;
$error-20: #ff2d2d;
$warning: #ff8233;
$warning-2: #ffa724;

$green-20: #edf5d7;
$green-40: #dde8b9;
$green-45: #d6e2b2;
$green-50: #d1dcaf;

$grey-1: #bebebe;
$grey-2: #838897;
$grey-3: #333;
$grey-4: #767676;
$grey-10: #404040;
$grey-20: #303a3d;

$border-primary: #e0e3f0;
$border-primary-dark: #a2a9b9;
$border-secondary: #cacfe2;
$border-secondary-dark: #b9bccb;
$border-accent: #e4f2bc;
$border-accent-secondary: #f1e4bd;

$separator: #e0e4f4;
$separator-2: #dadada;

$text-primary: #333;
$text-black-150: #040404;
$text-black-100: #303a3d;
$text-muted-70: #65696e;
$text-muted-30: #8d92a2;
$text-black-50: #222326;
$text-secondary: #747486;
$text-info: #797979;
$text-muted: #9a9a9a;

$green-100: #96cd00;
$green-50: #a5cc46;

$bg-dark-green: #0c592f;
$bg-dark-green-2: #106c3a;
$bg-primary: #f6f6f9;
$bg-primary-disabled: #ececf1;
$bg-secondary: #fff;
$bg-accent: #fff7dc;
$bg-error-light: #fbe9e9;
$bg-approved-card: #f2fadb;
$bg-extension-card: #f2f8e1;

$secondary-light: #e7f0eb;

$yellow-100: #ffa724;
$yellow-90: #ffa824;

// Breakpoints
$breakpoint-xxs: 400px;
$breakpoint-xs: 540px;
$breakpoint-s: 768px;
$breakpoint-m: 1024px;
$breakpoint-l: 1200px;
$breakpoint-xl: 1400px;

// Z-Indexes
$z-index-overlay: 10;

// Mixins
@mixin xxs {
  @media (max-width: #{$breakpoint-xxs}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$breakpoint-xs}) {
    @content;
  }
}

@mixin s {
  @media (max-width: #{$breakpoint-s}) {
    @content;
  }
}

@mixin m {
  @media (max-width: #{$breakpoint-m}) {
    @content;
  }
}

@mixin l {
  @media (max-width: #{$breakpoint-l}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$breakpoint-xl}) {
    @content;
  }
}

@mixin min-xxs {
  @media (min-width: #{$breakpoint-xxs}) {
    @content;
  }
}

@mixin min-xs {
  @media (min-width: #{$breakpoint-xs}) {
    @content;
  }
}

@mixin min-s {
  @media (min-width: #{$breakpoint-s}) {
    @content;
  }
}

@mixin min-m {
  @media (min-width: #{$breakpoint-m}) {
    @content;
  }
}

@mixin min-l {
  @media (min-width: #{$breakpoint-l}) {
    @content;
  }
}

@mixin min-xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

// Export variables so they can be imported in .tsx files
/* stylelint-disable property-no-unknown, order/properties-alphabetical-order */
:export {
  primary: $primary;
  secondary: $secondary;
  muted: $muted;
  error: $error;
  warning: $warning;
  border-primary: $border-primary;
  text-primary: $text-primary;
  text-secondary: $text-secondary;
  text-muted: $text-muted;
  bg-primary: $bg-primary;
  bg-secondary: $bg-secondary;
  bg-accent: $bg-accent;

  breakpoint-xxs: $breakpoint-xxs;
  breakpoint-xs: $breakpoint-xs;
  breakpoint-s: $breakpoint-s;
  breakpoint-m: $breakpoint-m;
  breakpoint-l: $breakpoint-l;
  breakpoint-xl: $breakpoint-xl;
}

/* stylelint-enable */
