@import '../../variables.module';

.container {
  width: 100%;
  @include m {
    width: unset;
  }
}

.link {
  text-decoration: none;
}

.button-container {
  width: 100%;

  .button {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px;
    width: 100%;

    @include s {
      padding: 20px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

.description {
  align-items: center;
  display: flex;
}

.icon {
  flex-shrink: 0;
  margin-right: 25px;
}

.heading,
.paragraph {
  text-align: left;
}

.label {
  background: $primary;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 6px;
  color: $bg-secondary;
  font-size: 11px;
  padding: 5px 11px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
}

.chevron {
  fill: $text-secondary;
  flex-shrink: 0;
  margin-left: 10px;
  transform: rotateZ(-90deg);
}

.new-label-icon {
  margin-left: 10px;
  margin-top: -2px;
  position: absolute;

  @include s {
    margin-top: -8px;
  }
}
