@import '../../variables.module';

.payment-schedule {
  font-size: 12px;
  margin-top: 10px;
}

.table-row {
  align-items: center;
  border-bottom: 1px solid $border-primary;
  color: #797979;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  line-height: 30px;

  .table-row {
    margin-top: 8px;
  }

  .table-row-value {
    align-items: center;
    display: flex;
  }

  .icon {
    margin-right: 5px;
  }
}

.table-row:last-child {
  border-bottom: 0;
}

.overdue-color-warning {
  color: $yellow-100;
}

.overdue-color-error {
  color: $error-background-100;
}

.paid-quota {
  color: $green-100;
}
