@import '../../variables.module';

.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only percy {
  .wrapper {
    display: none;
  }
}

.loader {
  display: inline-block;
  height: 80px;
  position: relative;
  width: 80px;
}

.circle {
  animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  border: 4px solid $primary;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
}

.circle:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes loader {
  0% {
    height: 0;
    left: 36px;
    opacity: 1;
    top: 36px;
    width: 0;
  }

  100% {
    height: 72px;
    left: 0;
    opacity: 0;
    top: 0;
    width: 72px;
  }
}
