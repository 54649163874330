@import '../../../variables.module';

.container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.app {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 1200px;
  padding: 20px;
  width: 100%;
}

.desktop-header {
  display: flex;

  @include m {
    display: none;
  }
}

.mobile-header {
  display: none;

  @include m {
    display: flex;
  }
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 560px;
  padding: 100px 20px 80px;
  width: 100%;

  @include m {
    padding: 15px;
  }
}

.notifications {
  top: 60px;
}

.footer {
  @include m {
    display: none;
  }
}
