@import '../../variables.module';

.card {
  overflow: hidden;
  padding: 0;
}

.extension {
  border-bottom: 1px solid $border-primary;
  display: flex;
  justify-content: space-between;
  padding: 30px 23px;
}

.term,
.fee {
  color: $text-primary;
  font-size: 24px;
  font-weight: 500;

  @include s {
    font-size: 20px;
  }
}

.fee {
  &.discounted {
    color: #ff6402;
  }
}

.fee-before-discount {
  color: $text-secondary;
  font-size: 14px;
  margin-right: 12px;
  text-decoration: line-through;
}

.schedule {
  background-color: #f9f9fb;
  padding: 30px 23px 0 30px;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 11px;
  width: 100%;
}

.header-date {
  color: $text-primary;
  font-size: 14px;
  font-weight: 500;
  width: 82px;
}

.schedule-item {
  align-items: center;
  border-top: 1px solid $border-primary;
  display: flex;
  justify-content: flex-end;
  padding: 26px 0 20px;
}

.arrow {
  fill: $text-secondary;
  padding: 0 16px;
  transform: rotate(270deg);
  width: 7px;
}

.current-date {
  color: $text-secondary;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
}

.new-date {
  color: $text-primary;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  width: 82px;
}
