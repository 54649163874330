@import '../../variables.module';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.app {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 1200px;
  padding: 40px 20px 20px;
  width: 100%;

  @include m {
    padding: 0;
  }
}

.dashboard {
  display: flex;
  flex: 1;

  @include m {
    flex-direction: column;
  }
}

.mobile-header {
  box-sizing: border-box;
  display: none;
  padding: 40px 20px;

  @include m {
    display: flex;
    padding: 20px;
  }
}

.aside {
  display: flex;
  flex-direction: column;
  width: 220px;

  @include m {
    display: none;
  }
}

.sidebar {
  position: sticky;
  top: 40px;
}

.main {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  isolation: isolate;
  padding: 0 0 0 80px;
  width: 100%;

  @include m {
    padding: 0 20px;
  }

  &.small {
    margin-bottom: 100px;
    max-width: 560px;

    @include m {
      max-width: none;
    }
  }
}

.back-wrapper {
  display: flex;

  @include m {
    display: none;
  }
}

.back {
  margin-bottom: 70px;
}

.chevron {
  margin-right: 9px;
  transform: rotateZ(90deg);
}

.overdue-banner {
  align-items: center;
  color: $bg-secondary;
  display: flex;
  font-size: 19px;
  font-weight: 500;
  justify-content: center;
  line-height: 19px;
  padding: 10px 0;
  width: 100%;

  .overdue-banner-icon {
    margin-right: 10px;
  }

  .overdue-banner-days {
    background-color: $bg-secondary;
    border-radius: 5px;
    margin-left: 5px;
    padding: 3px;
  }

  .overdue-warning-banner-days {
    color: $yellow-100;
  }

  .overdue-error-banner-days {
    color: $error-background-100;
  }
}

.overdue-banner-error {
  background-color: $error-background-100;
}

.overdue-banner-warning {
  background-color: $yellow-100;
}
