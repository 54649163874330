@import '../../variables.module';

.container {
  display: flex;
  padding: 0;
  position: relative;

  &:hover {
    background-color: darken(#fff, 3%);
  }

  &:focus-within {
    box-shadow: 0 0 8px 0 rgba($primary, 60%);
  }

  &.checked {
    background-color: $primary;
    border-color: darken($primary, 5%);
  }
}

.input {
  cursor: pointer;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.checkmark-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 35px 40px;

  @include s {

    padding: 33px 27px 34px 28px;
  }
}

.checkmark {
  background-color: #fff;
  border: 2px solid $border-primary;
  border-radius: 50%;
  box-sizing: border-box;
  height: 18px;
  padding: 3px;
  width: 18px;

  &.checked {
    border-style: none;
  }
}

.checkmark-filled {
  background-color: $secondary;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.content {
  align-self: center;
  color: #fff;
  flex: 1;
  padding: 23px 23px 23px 0;
}
